import Fade from 'react-reveal/Fade';

const Profile = () => {
    return (
        <section>
            <Fade right distance="3rem">
                <p className="text-lg pb-8">
                    Senior Fullstack Developer with over a decade experience building web apps, enterprise platforms and microsites using modern tech stacks, APIs and microservices. Committed to building performant, accessible, visually engaging presentation layers and secure, scalable backends.
                </p>
                <p className="text-lg">
                    A creative, adaptable problem-solver with a strong ethical mindset.
                </p>
            </Fade>
        </section>
    )
};

export default Profile;